<template>
	<div>
		<Navbar />
	    <Menu v-can="'admin'"/>
		<MenuPenjual v-can="'penjual'"/>
	    <div class="app-content content">
	        <div class="content-wrapper" style="margin-top: 5rem">
				<img src='/assets/img/backdrop.png' class="img-fluid" style="
					position: absolute;
					left: 0;
					top: 64px;
					z-index: -1;
					width: 100%;
    				height: 167px;
				">
				<div class="content-body">
					<div class="mb-2">
						<router-link :to="$route.meta.redirect ? $route.meta.redirect : ''">
							<i class="material-icons text-white">{{ $route.meta.icon }}</i>
							<span class="menu-title align-self-center ml-1 text-white" style="vertical-align: super;">{{ $route.meta.pageTitle }}</span>
						</router-link>
					</div>
					<router-view/>
				</div>
	        </div>
	    </div>
		<div class="sidenav-overlay"></div>
	</div>
</template>

<script>
import Navbar from '@/layouts/components/Navbar'
import Menu from '@/layouts/components/Menu'
import MenuPenjual from '@/layouts/components/MenuPenjual'
export default {
	components: {
		Navbar,
		Menu,
		MenuPenjual
	}
}
</script>