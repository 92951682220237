<template>
	<nav class="header-navbar navbar-expand-lg navbar navbar-with-menu floating-nav navbar-light navbar-shadow" style="margin: 0px;">
        <div class="navbar-wrapper pr-1">
            <div class="navbar-container content">
                <div class="navbar-collapse" id="navbar-mobile">
                    <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                        <!-- Mobile -->
                        <ul class="nav navbar-nav">
                            <li class="nav-item mobile-menu d-lg-none mr-auto">
                                <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#" data-toggle="collapse">
                                    <i class="toggle-icon collapse-toggle-icon ficon feather icon-menu" data-ticon="icon-disc"></i>
                                </a>
                            </li>
                        </ul>

                        <!-- Desktop -->
                        <ul class="nav navbar-nav bookmark-icons">
                            <li class="nav-item d-none d-lg-block">
                                <a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
                                    <i class="toggle-icon collapse-toggle-icon ficon feather icon-menu" data-ticon="icon-disc"></i>
                                </a>
                            </li>
                        </ul>
                    </div>   
                    <ul class="nav navbar-nav float-right">
                        <li class="dropdown dropdown-notification nav-item mr-1">
                            <a class="nav-link nav-link-label" href="#" data-toggle="dropdown">
                                <i class="ficon feather icon-bell text-center"></i>
                                <span class="badge badge-pill badge-danger badge-up" v-if="notification_total_unread > 0">{{ notification_total_unread }}</span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
                                <li class="dropdown-menu-header">
                                    <div class="dropdown-header m-0">
                                        <span class="notification-title">Notifications</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="count-notif-block">
                                        <p class="font-600 text-primary mb-0">All ({{ notification_total }})</p>
                                    </div>
                                </li>
                                <li class="scrollable-container media-list">
                                    <div v-for="data in notifications" :key="data.id" class="pointer" :class="{'readed': data.is_read }"
                                        @click="!data.is_read ? readNotification(data.id) : false">
                                        <div class="media">
                                            <div class="media-body">
                                                <div class="row justify-content-between">
                                                    <div class="col-7">
                                                        <h6 class="media-heading">{{ data.name }}</h6>
                                                    </div>
                                                    <div class="col-5 text-right">
                                                        <small>{{ fromNow(data.updated_at) }}</small>
                                                    </div>
                                                </div>
                                                <p class="notification-text mb-0">{{ html_striptag(data.description) }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="dropdown-menu-footer" v-if="page != notification_last_page" @click="page+=1">
                                    <div class="dropdown-item p-1 text-center">Muat lebih banyak <i class="fa fa-arrow-down"></i></div>
                                </li>
                            </ul>
                        </li>
                        <li class="dropdown dropdown-user nav-item">
                            <a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                <span>
                                    <img class="round" :src="user.photo_profile ? user.photo_profile:'/assets/img/icon/profile.png'" alt="avatar" height="40" width="40">
                                </span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <div class="col" style="margin-top: 5px;">
                                    <p class="text-primary font-600 f-16 mb-0">{{ user.name }}</p>
                                    <p class="text-black f-12 mb-0">{{ user.role_name }}</p>
                                </div>
                                <div class="dropdown-divider"></div>
                                <router-link to="/profile/edit" tag="a" class="dropdown-item">
                                    <i class="feather icon-user"></i> Profil
                                </router-link>
                                <router-link to="/profile/change_password" tag="a" class="dropdown-item">
                                    <i class="feather icon-lock"></i> Ubah Password
                                </router-link>
                                <div class="dropdown-divider"></div>
                                <a href="javascript:;" @click="logout()" class="dropdown-item">
                                	<i class="feather icon-power"></i> Keluar
                                </a>
                            </div>
                        </li>
                    </ul>                 
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import $ from 'jquery'

export default {
    data() {
        return {
            isFullScreen: 0,
            notifications: [],
            temp_photo: null,
            user: JSON.parse(localStorage.getItem('user')),
            page: 1,
            notification_total: 0,
            notification_last_page: 1,
            notification_total_unread: 0
        }
    },
	methods: {
        getNotification() {
            let _ = this
            _.axios.get('/role/notification/my?limit=5&page=' + _.page)
                .then(resp => {
                    resp.data.data.data.forEach(elm => {
                        _.notifications.push(elm)
                    });

                    _.notification_last_page = resp.data.data.last_page
                    _.notification_total = resp.data.data.total
                })
        },
        readNotification(id) {
            let _ = this
            _.axios.get('/role/notification/' + id)
                .then( () => {
                    _.notifications = _.notifications.map(n => {
                        if ( id == n.id ) {
                            return { ...n, is_read: true }
                        } else {
                            return n
                        }
                    })
                    _.notification_total_unread -= 1
                })
        },
        getTotalUnreadNotification()
        {
            let _ = this
            _.axios.get('/role/notification/total_unread')
                .then(resp=>{
                    _.notification_total_unread = resp.data.data
                })
        },
        logout() {
            let _ = this
            $(function(){                
                $('body').removeClass('menu-collapsed')
                $('body').addClass('menu-expanded')
            })
			_.$store.dispatch('logout')
			_.$router.push('/login')
        },
        html_striptag(str) {
            return str.replaceAll('</p><p>', ' ').replace(/(<([^>]+)>)/gi, "")
        },
        fromNow( date ){
            return this.$moment(date).fromNow()
        }
	},
    mounted() {
        let _ = this

        _.getNotification()
        _.getTotalUnreadNotification()

        _.$root.$on('changeProfile', (data) => {
            _.user = data
            localStorage.setItem('user', JSON.stringify(_.user))
        })

        window.$(document).on('click', '.nav .dropdown-menu', function (e) {
            e.stopPropagation();
        });

        window.$(document).on('click', '.nav .dropdown-menu-footer', function (e) {
            e.stopPropagation();
        });
    },
    watch: {
        page: function()
        {
            this.getNotification()
        }
    }
}
</script>